<template>
  <ul
    class="help lg:grid-cols-1"
    :class="[displayChat ? 'grid grid-cols-2' : '']"
    :style="getHelpButtonsPosition"
  >
    <li class="bg-brand-grey-700 text-white">
      <a
        href="https://tafensw.custhelp.com/app/contact-us/enquire?campaign=e2e"
        target="_blank"
        rel="noopener noreferrer"
        class="flex flex-col w-full items-center p-3"
        @click="gtag('event', 'enquireClick')"
        ><Icon name="IconCircleQuestion" class="text-3xl" />
        <span class="text-sm">Enquire</span>
      </a>
    </li>
    <li
      class="bg-brand-grey-700 text-white lg:mt-1"
      :class="{ hidden: !displayChat }"
    >
      <button
        id="rn_sccl_0_Link"
        type="button"
        class="flex flex-col items-center w-full p-3"
        @click="myChatWidget.openMyChat()"
      >
        <Icon name="IconComments" class="text-3xl" />
        <span class="text-sm">Chat</span>
      </button>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { computed, ref, onMounted } from 'vue'
import { useUiStore } from '~/stores/ui'
import useExternalWidget from '~/composables/useExternalWidget'
const viewport = useViewport()
const myChatWidget = useExternalWidget()
const uiStore = useUiStore()
const { gtag } = useGtag()
interface HelpButtonsProps {
  chatStatus: boolean | null
}

const props = withDefaults(defineProps<HelpButtonsProps>(), {
  chatStatus: null
})

const position = ref('')

const getHelpButtonsPosition = computed(() => {
  return viewport.isGreaterOrEquals('lg') ? 'position:' + position.value : ''
})

const getFooterElement = (...ids: string[]): HTMLElement | null => {
  for (const id of ids) {
    const element = document.getElementById(id)
    if (element) return element
  }
  return null
}

// switch myChat widget control between feature flag and default weekday set up
const displayChat = computed(() => {
  if (props.chatStatus !== null) {
    return props.chatStatus
  } else {
    // get default live chat display status during weekday between 8am and 5:45pm
    return uiStore.isChatOpen
  }
})

onMounted(() => {
  uiStore.checkChatHours()
  // observing footer
  const footer = getFooterElement('int-app-footer', 'default-app-footer')
  if (footer) {
    stickyAboveFooter(footer)
  }
})

function stickyAboveFooter(footer: HTMLElement) {
  const observer = new IntersectionObserver(
    (entry) => {
      if (entry[0].intersectionRatio > 0) {
        position.value = 'absolute'
      } else {
        position.value = 'fixed'
      }
    },
    {
      threshold: 0 // one pixel visible of footer container trigger callback
    }
  )
  observer.observe(footer)
}
</script>

<style lang="postcss" scoped>
.help {
  @screen lg {
    @apply right-0 text-center text-xs leading-none z-0 bottom-24;
  }
}
</style>
